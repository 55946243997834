import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SkillModal from "../components/SkillModal";
import content from "../content";

export default function Stack() {
  return (
    <div
      className="min-h-screen flex items-center justify-center flex-col"
      style={{
        background: "#eee",
      }}
    >
      <h1 className="text-5xl font-dosis font-bold mb-10">Stack I Use</h1>
      <div>
        <div className="flex flex-wrap justify-center">
          {content.stack.tech.map((tech, index) => (
            <>
              <SkillModal data={tech}>
                <span
                  key={index}
                  style={{ cursor: "pointer" }}
                  className={` h-40 w-40 bg-white shadow-xl  ml-2  rounded-full flex justify-center items-center p-5 m-2 relative ${
                    index % 2 !== 0 ? "animate-bounce" : "animate-bounceFirst"
                  }`}
                >
                  <p className="absolute top-0 text-white w-10 h-10 text-center rounded-full flex items-center justify-center bg-red-500 -mr-24">
                    {tech.years}
                  </p>
                  <LazyLoadImage effect="blur" src={tech.img} alt={tech.alt} />
                  <h1 className="absolute bottom-0 text-xl font-dosis font-bold">
                    {tech.name}
                  </h1>
                </span>
              </SkillModal>
            </>
          ))}
        </div>
      </div>
      <p className="mt-10 mb-10 w-11/12 md:max-w-xl text-center inline-block text-xl md:text-2xl font-dosis ">
        {content.stack.desc}.
      </p>
    </div>
  );
}
