import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
export const GitHubContributions = () => {
  return (
    <div className="min-h-screen flex justify-center items-center flex-col">
      <h1 className="text-5xl font-dosis font-bold">GitHub Contributions</h1>
      <p className="text-gray-600 text-2xl font-dosis mb-10">
        (Private Repositories Not Included)
      </p>
      <LazyLoadImage
        effect="blur"
        src="https://ghchart.rshah.org/menendezluis"
        alt="GitHub Contributions"
      />
    </div>
  );
};
