import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./skillmodal.style.css";
function Example({ data, children }) {
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState(data);

  const toggle = () => {
    console.log("click");
    setModal(!modal);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop={true} fullscreen={true}>
        <ModalHeader toggle={toggle}>
          <div className="d-flex w-full justify-content-center">
            <img
              src={item.img}
              alt={item.name}
              style={{
                width: "100%",
                height: "100px",
                objectFit: "cover",
              }}
            />

            <div className="modal-title">{item.largeName}</div>
          </div>
        </ModalHeader>
        <ModalBody>
          {item?.attributes?.map((attr, index) => {
            return (
              <div className="grid-container">
                <div className="tecnologia">{attr.name}</div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    style={{ width: attr.value + "%" }}
                  ></div>
                </div>
                <div className="porcentaje">{attr.value}%</div>
              </div>
            );
          })}
          <p>
            Years of experience: <strong>{item.years}</strong>
          </p>
          <p>Total of projects: {item.totalProjects}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <div onClick={() => toggle()} style={{ cursor: "pointer" }}>
        {children}
      </div>
    </>
  );
}

export default Example;
