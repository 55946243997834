import React from "react";
import { UncontrolledCarousel } from "reactstrap";
import "./style.css";
import content from "../content";

const Slider = () => {
  return (
    <>
      {" "}
      <div
        className="min-h-screen flex justify-center items-center flex-col"
        style={{
          background: "#FEFEFE",
        }}
        id="mywork"
      >
        <h1 className="text-5xl font-dosis font-bold">
          {content.portfolio.title}
        </h1>
        <p className="text-gray-600 text-2xl font-dosis mb-10">
          This is a brief list of my work
        </p>

        <UncontrolledCarousel
          className="slider"
          items={content.portfolio.projects}
          fadeIn="true"
          dark="true"
        />
      </div>
    </>
  );
};

export default Slider;
