export default {
  nav: {
    logo: "LM",
    links: [
      { text: "About me", to: "mywork" },
      { text: "Contact", to: "mycontact" },
    ],
  },
  header: {
    img: process.env.PUBLIC_URL + "/assets/profile_picture.jpg",
    imgPlaceholder: process.env.PUBLIC_URL + "/assets/profile_picture.jpg",
    text: ["Hi!", "It's me Luis Menendez.", "I am "],
    typical: [
      "web developer. 💻 ",
      2000,
      "FrontEnd Developer. 🌐 ",
      2000,
      "Backend Developer. 🔐",

      "Serverless Developer. 🚀 ",
      2000,
      "video games player.🎮",
      2000,
      "Backend Developer. 🖥️ ",
      2000,
      "Dog dad.🐶",
      2000,
      "Backpacker. 🗺️",
      2000,
      "Dreamer. 🦄",
      2000,
      "Guatemalan. 🔥",
      6000,
    ],
    btnText: "Take a look",
  },
  work: {
    title: "About me?",
    img: process.env.PUBLIC_URL + "/assets/projects.png",
    imgPlaceholder: "content.work.img",
    projectName: "Biography",
    gitHubContribution: "GitHub Contributions",
    desc: "Hello there! I'm a seasoned software developer with a passion for exploration and continuous learning. At 34 years young, I thrive on adventures, often opting for backpacking journeys over Netflix binges. You'll usually find me engrossed in a captivating book, taking a leisurely ride, or delving into the realms of new knowledge.\nMy journey in the IT industry spans over a decade, but it was in 2019 when I stumbled upon React.js that my fascination truly ignited. The endless possibilities it offers in crafting innovative solutions captivated me from the start.\n\nI'm on a mission to showcase my expertise through a collection of projects centered around React, the dynamic MERN stack, and Python, often intertwined with other cutting-edge digital technologies. Join me as I embark on this exciting journey of creation and discovery!",
  },
  portfolio: {
    title: "Portfolio",
    projects: [
      {
        src: process.env.PUBLIC_URL + "/assets/nombolo.png",
        altText: "APP",
        caption: "Nombolo APP",
        header:
          "Mobile Developer - React Native: Expo, AWS, Lambda, Dynamo, Cognito, SNS, RestAPI",
        key: "1",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/tru.png",
        altText: "APP",
        caption: "Tru APP",
        header:
          "Mobile Developer - React Native, React, AWS, Lambda , Dynamo, Cognito, SNS, RestAPI",
        key: "1",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/elmerapp.png",
        altText: "APP",
        caption: "Elmer APP & Web",
        header:
          "Mobile Developer - React Native, React, Google Cloud, Firestore, Auth, Storage, Functions, Hosting",
        key: "1",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/mytheresa.png",
        altText: "APP",
        caption: "Mytheresa APP",
        header:
          "Mobile Developer - React Native: Expo, AWS, Lambda, Dynamo, Cognito, SNS, RestAPI",
        key: "1",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/UpscriptLanding.png",
        altText: "APP",
        caption: "Upscript Landing",
        header:
          "Frontend Developer - React, Next.js, Wordpress, Cognito, RestAPI",
        key: "1",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/Upscript.png",
        altText: "APP",
        caption: "Upscript Ecommerce",
        header:
          "Frontend Developer - React, Next.js, Wordpress, Cognito, RestAPI",
        key: "1",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/PivotHealth.png",
        altText: "APP",
        caption: "Healthcare Web Page",
        header: "Frontend Developer - React, Handlebars, Wordpress",
        key: "1",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/lk1.png",
        altText: "System",
        caption: "LikeInc",
        header:
          "Fullstack - Campaigns System: React, Node, AWS: Lambda, Dynamo, Cognito, SNS, RestAPI Chartjs",
        key: "2",
      },

      {
        src: process.env.PUBLIC_URL + "/assets/chatql.png",
        altText: "ChatQL",
        caption: "ChatQL",
        header:
          "Fullstack - Chat & Queue Managment: React, Aws, Dynamo, GraphQL, Cognito and  Anonymous login for embebed on websites",
        key: "4",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/lk2.png",
        altText: "System",
        caption: "LikeInc",
        header:
          "Fullstack - Campaigns System: React, Node, AWS: Lambda, Dynamo, Cognito, SNS, RestAPI, Bucket S3, Chartjs",
        key: "5",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/lk3.png",
        altText: "System",
        caption: "LikeInc",
        header: "GeoPoint Api, Pixel to Meter Convertor, multiple Lat and Long",
        key: "6",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/chatql2.jpg",
        altText: "ChatQL",
        caption: "ChatQL",
        header: "Chat for embebed on websites",
        key: "7",
      },
    ],
  },
  stack: {
    title: "Fullstack Developer",
    tech: [
      {
        name: "AWS",
        value: "75",
        years: "5",
        largeName: "Amazon Web Services",
        totalProjects: 7,
        attributes: [
          { name: "EC2", value: "100" },
          { name: "S3", value: "100" },
          { name: "RDS", value: "80" },
          { name: "CloudFront", value: "80" },
          { name: "Lambda", value: "100" },
          { name: "SNS", value: "100" },
          { name: "Route53", value: "70" },
          { name: "DynamoDB", value: "100" },
          { name: "CloudWatch", value: "80" },
          { name: "Amplify", value: "100" },
          { name: "AppSync", value: "50" },
        ],
        img: process.env.PUBLIC_URL + "/assets/aws.png",
        alt: "aws",
      },

      {
        name: "React",
        value: "90",
        years: "5+",

        largeName: "React.js",
        totalProjects: "15+",
        attributes: [
          { name: "React", value: "100" },
          { name: "React Router", value: "100" },
          { name: "Redux", value: "100" },
          { name: "React Hooks", value: "85" },
          { name: "React Context", value: "85" },
          { name: "React Redux", value: "90" },
          { name: "Class Component", value: "85" },
        ],
        img: process.env.PUBLIC_URL + "/logo512.png",
        alt: "react",
      },
      {
        name: "Node.js",
        value: "100",
        years: "7+",
        largeName: "Node.js",
        totalProjects: "15+",

        attributes: [
          { name: "Node.js", value: "100" },
          { name: "Express", value: "100" },
          { name: "Axios", value: "100" },
          { name: "Mongoose", value: "100" },
          { name: "Passport", value: "80" },
          { name: "JWT", value: "80" },
          { name: "Nodemailer", value: "100" },
          { name: "Multer", value: "50" },
          { name: "Cors", value: "100" },
          { name: "Bcrypt", value: "100" },
          { name: "Morgan", value: "50" },
          { name: "Dotenv", value: "100" },
          { name: "Compression", value: "100" },
          { name: "Lodash", value: "100" },
        ],
        img: process.env.PUBLIC_URL + "/assets/node.png",
        alt: "node",
      },
      {
        name: "Python",
        value: "75",
        years: "3+",
        largeName: "Python",
        totalProjects: "3",
        attributes: [
          { name: "Python", value: "75" },
          { name: "Flask", value: "75" },
          { name: "Django", value: "75" },
        ],
        img: process.env.PUBLIC_URL + "/assets/python.png",
        alt: "python",
      },
      {
        name: "MongoDB",
        value: "90",
        years: "5+",
        largeName: "MongoDB",
        totalProjects: "8+",
        attributes: [
          { name: "MongoDB", value: "100" },
          { name: "Mongoose", value: "100" },
          { name: "MongoDB Atlas", value: "75" },
          { name: "Populate", value: "100" },
          { name: "Aggregation", value: "100" },
          { name: "Indexes", value: "100" },
          { name: "Sharding", value: "50" },
          { name: "Replication", value: "50" },
        ],
        img: process.env.PUBLIC_URL + "/assets/mongo.png",
        alt: "mongo",
      },
      {
        name: "Firebase",
        value: "90",
        years: "5+",
        largeName: "Firebase",
        totalProjects: "10+",
        attributes: [
          { name: "Firebase", value: "100" },
          { name: "Firestore", value: "100" },
          { name: "Firebase Auth", value: "100" },
          { name: "Firebase Storage", value: "100" },
          {
            name: "Cloud Functions",
            value: "100",
          },
          { name: "Firebase Hosting", value: "100" },
          { name: "Firebase Analytics", value: "50" },
        ],
        img: process.env.PUBLIC_URL + "/assets/firebase.png",
        alt: "firebase",
      },
      {
        img: process.env.PUBLIC_URL + "/assets/html5-css3-js.png",
        alt: "HTML5-CSS3-JS",
        name: "HTML5",
        value: "100",
        years: "10+",
        largeName: "HTML5 - CSS3 - JS",
        totalProjects: "30+",
        attributes: [
          { name: "HTML5", value: "100" },
          { name: "CSS3", value: "100" },
          { name: "JS", value: "100" },
          { name: "Bootstrap", value: "100" },
          { name: "Tailwind", value: "100" },
          { name: "Materialize", value: "100" },
          { name: "Material UI", value: "100" },
          { name: "Ant Design", value: "100" },
          { name: "Semantic UI", value: "100" },
          { name: "Styled Components", value: "100" },
        ],
      },
      {
        name: "Next.js",
        value: "100",
        years: "5+",
        largeName: "Next.js",
        totalProjects: "7+",
        attributes: [
          { name: "Next.js", value: "100" },
          { name: "SSR", value: "100" },
          { name: "ISR", value: "100" },
          { name: "SSG", value: "60" },
        ],
        img: process.env.PUBLIC_URL + "/assets/next.png",
        alt: "Next.js",
      },
      {
        name: "Nest.js",
        value: "100",
        years: "3+",
        largeName: "Nest.js",
        totalProjects: "5+",
        attributes: [
          { name: "Nest.js", value: "100" },
          { name: "TypeORM", value: "80" },
          { name: "Passport", value: "80" },
          { name: "JWT", value: "80" },
        ],
        img: process.env.PUBLIC_URL + "/assets/nest.png",
        alt: "Nest.js",
      },
      {
        name: "Google Cloud",
        value: "100",
        years: "5+",

        largeName: "Google Cloud Platform",
        totalProjects: "7+",
        attributes: [
          { name: "Google Cloud", value: "100" },
          { name: "Google Cloud Functions", value: "100" },
          { name: "Google Cloud Storage", value: "100" },
          { name: "Google Cloud Firestore", value: "Google Cloud Firestore" },
          { name: "VM Instances", value: "100" },
          { name: "Google Domains", value: "100" },
          { name: "Google Cloud Run", value: "100" },
          { name: "Google Cloud SQL", value: "100" },
        ],
        img: process.env.PUBLIC_URL + "/assets/googlecloud.png",
        alt: "Google Cloud",
      },
      {
        name: "React Native",
        years: "5+",
        value: "100",
        largeName: "React Native",
        totalProjects: "8+",
        attributes: [{ name: "React Native", value: "React Native" }],
        img: process.env.PUBLIC_URL + "/assets/reactnative.png",
        alt: "React Native CLI",
        attributes: [
          { name: "React Native", value: "100" },
          { name: "Expo", value: "100" },
          { name: "React Navigation", value: "80" },
          { name: "Redux", value: "80" },
          { name: "React Hooks", value: "80" },
          { name: "React Context", value: "100" },
          { name: "React Redux", value: "100" },
          { name: "Class Component", value: "100" },
          { name: "Functional Component", value: "100" },
          { name: "Native Modules", value: "100" },
          { name: "Native Components", value: "100" },
          { name: "Android", value: "80" },
          { name: "IOS", value: "80" },
          { name: "React Native CLI", value: "80" },
          { name: "React Native Navigation", value: "100" },
          { name: "React Native Gesture Handler", value: "100" },
          { name: "React Native Reanimated", value: "100" },
          { name: "React Native Vector Icons", value: "100" },
          { name: "React Native Paper", value: "100" },
        ],
      },
      {
        name: "Expo",
        years: "5+",
        value: "100",
        largeName: "Expo",
        totalProjects: "8+",

        attributes: [{ name: "Expo", value: "Expo" }],
        img: process.env.PUBLIC_URL + "/assets/expo.png",
        alt: "Expo",
        attributes: [
          { name: "Expo", value: "100" },
          { name: "Expo CLI", value: "100" },
          { name: "Expo Bare Workflow", value: "100" },
          { name: "Expo Managed Workflow", value: "100" },
          { name: "Expo Eject", value: "100" },
          { name: "Expo Push Notifications", value: "100" },
          { name: "Expo Permissions", value: "100" },
          { name: "Expo Camera", value: "100" },
          { name: "Expo Image Picker", value: "100" },
          { name: "Expo Location", value: "100" },
          { name: "Expo BarCode Scanner", value: "100" },
          { name: "Expo SecureStore", value: "100" },
          { name: "Expo Linking", value: "100" },
          { name: "Expo WebBrowser", value: "100" },
          { name: "Expo Facebook", value: "100" },
          { name: "Expo Google", value: "100" },
          { name: "Expo Apple", value: "100" },
          { name: "Expo AuthSession", value: "100" },
          { name: "Expo Notifications", value: "100" },
          { name: "Expo SecureStore", value: "100" },
          { name: "Expo SplashScreen", value: "100" },
          { name: "Expo AppLoading", value: "100" },
          { name: "Expo Fonts", value: "100" },
          { name: "Expo Audio", value: "100" },
          { name: "Expo Video", value: "100" },
          { name: "Expo Lottie", value: "100" },
          { name: "Expo BlurView", value: "100" },
          { name: "Expo LinearGradient", value: "100" },
        ],
      },
    ],
    desc: `The STACK than i use is based in React, AWS, Node.js, MongoDB, Python and Firebase, of course HTML5 and CSS3 is a most;  I ❤️Serverless.`,
  },
  contact: {
    title: "Ping Me!",
    desc: "You can contact me in the Following networks.",
    socials: [
      {
        alt: "facebook",
        img: process.env.PUBLIC_URL + "/assets/facebook.png",
        link: "http://www.facebook.com/luis.menendez1",
      },
      {
        alt: "github",
        img: process.env.PUBLIC_URL + "/assets/github.png",
        link: "https://github.com/menendezluis/",
      },
      {
        alt: "link",
        img: process.env.PUBLIC_URL + "/assets/link.png",
        link: "https://www.linkedin.com/in/luis-menendez-a2437956/",
      },
      {
        alt: "gmail",
        img: process.env.PUBLIC_URL + "/assets/gmail.png",
        link: "luis@luismenendez.dev",
      },
    ],
    img: process.env.PUBLIC_URL + "/assets/miniprofile.png",
    imgPlaceholder: process.env.PUBLIC_URL + "/assets/miniprofile.png",
  },
};
